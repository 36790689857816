import {useEffect, useState} from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import {CircularProgress, Grid, LinearProgress, Stack} from "@mui/material";
import TableBarIcon from '@mui/icons-material/TableBar';

import dayjs from "dayjs";

const cookies = new Cookies();

export default function Station() {
  const token = cookies.get("NEO_STATION_TOKEN");
  const restaurantId = cookies.get("NEO_STATION_RESTAURANT_ID");

  const [orders, setOrders] = useState([]);
  const [removedOrderIds, setRemovedOrderIds] = useState([]);
  const [loading, setLoading] = useState(false);

  const configuration = {
    method: "GET",
    url: `https://neomenu-backend.fly.dev/restaurants/${restaurantId}/orders`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const fetchOrders = () => {
      axios(configuration)
        .then(retrievedOrders => {
          let updatedOrders = [...retrievedOrders.data.data];

          // Filter out removed orders
          updatedOrders = updatedOrders.filter(order => !removedOrderIds.includes(order._id));

          // Filter out orders older than 5 hours
          const currentDateTime = new Date();
          const fiveHoursAgo = new Date(currentDateTime - 5 * 60 * 60 * 1000);

          updatedOrders = updatedOrders.filter(order => new Date(order.createdAt) > fiveHoursAgo);

          // Sort orders by createdAt
          updatedOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

          // Set the filtered and sorted orders
          setOrders(updatedOrders);
        })
        .catch(error => {
          console.error("Error fetching orders:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    };


    setLoading(true);
    fetchOrders();

    const intervalId = setInterval(fetchOrders, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [restaurantId, token, removedOrderIds]);

  const handleOrderClick = (orderId) => {
    setRemovedOrderIds(prevIds => [...prevIds, orderId]);
  };

  const getMinutesAgo = (createdAt) => {
    const currentTime = dayjs();
    const orderTime = dayjs(createdAt);
    const minutesAgo = currentTime.diff(orderTime, 'minute');
    return `${minutesAgo} mins ago`;
  };

  if (loading) {
    return <div style={{display: 'flex', justifyContent: "center", paddingTop: "10px", color: "#1ea59a"}}>
      <CircularProgress color="inherit"/>
    </div>;
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={2}></Grid>
      <Grid item xs={8}>
        <Stack p={2} spacing={2}>
          {orders.length > 0 ? orders.map(order => {
            return <div style={{display: 'flex', justifyContent: "center"}}>
              <div key={order.id}
                   style={{
                     border: "2px solid black",
                     width: "600px",
                     borderBottomRightRadius: "5px",
                     borderBottomLeftRadius: "5px"
                   }}>
                <Grid container>
                  <Grid item xs={12}>
                    <div style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      padding: "5px",
                      background: "black",
                      color: "white",
                    }}>
                      <div style={{fontSize: "24px"}} className="d-flex justify-content-between px-3">
                        <div>
                          <TableBarIcon style={{marginBottom: "4px", marginRight: "4px"}}/>
                          {order.tableName}
                        </div>
                        <div style={{fontSize: "24px"}}>
                          {getMinutesAgo(order.createdAt)}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  {order.comment ?
                    <Grid item xs={12}>
                      <div style={{textAlign: "center", padding: "4px", borderBottom: "1px solid black"}}>
                        {order.comment}
                      </div>
                    </Grid> : ''}
                </Grid>
                <Grid container p={2} spacing={1}>
                  {order.requests.map(r => {
                    return <>
                      <Grid item p={0} xs={2}>
                        <div style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          textAlign: "center",
                          padding: "5px",
                          background: "#eee",
                        }}>{r.quantity}</div>
                      </Grid>
                      <Grid item p={0} xs={10}>
                        <div style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          textAlign: "center",
                          padding: "5px",
                          background: "#eee",
                        }}>{r.item.name}</div>
                      </Grid>
                    </>
                  })}
                </Grid>
              </div>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: "#2dc42d",
                width: "100px",
                height: "100px",
                fontSize: "56px",
                color: "white",
                cursor: "pointer",
                fontWeight: "bold",
              }} onClick={() => handleOrderClick(order._id)}>
                ✓
              </div>
            </div>
          }) : (
            <div style={{display: 'flex', justifyContent: "center", flexDirection: "column"}}>
              <h3 className="mb-3" style={{textAlign: "center", fontWeight: "bold"}}>Waiting for new orders...</h3>
              <div style={{color: "#1ea59a", width: "300px", margin: "auto"}}><LinearProgress color="inherit"/></div>
            </div>
          )}
        </Stack>
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  )
}